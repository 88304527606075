import { Component } from 'react';
import Router from 'next/router';
import ReCAPTCHA from 'react-google-recaptcha';
import Field from 'components/Field';
import FieldGroup from 'components/FieldGroup';
import Button from 'components/Button';
import ALink from 'components/ALink';
import UnauthLayout from 'components/UnauthLayout';
import Icon from 'components/Icon';
import { fieldValue } from 'classes/Form';
import { inputByName, putTooltip } from 'classes/Dom';
import { UserContext } from 'context/UserContext';
import csrf from 'libs/csrf';
import { fetcher } from 'libs/fetcher';
import { withSessionSsr } from 'libs/iron-session';

export const getServerSideProps = withSessionSsr(async ({ req, res }) => {
  await csrf(req, res);
  const csrfToken = req.csrfToken();
  const recaptchaSiteKey = process.env.RECAPTCHA;

  return { props: { csrfToken, recaptchaSiteKey } };
});

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestError: false,
      requestBtn: 'normal',
      token: '',
    };
  }

  /**
   * Login method
   * @param  {Object} e Event
   */
  async doLogin(e) {
    e.preventDefault();

    const { csrfToken } = this.props;
    const email = fieldValue('email')?.trim();
    const password = fieldValue('password')?.trim();

    const body = {
      email,
      password,
    };

    if (!email) {
      const emailInput = inputByName('email');
      emailInput.focus();

      return putTooltip(emailInput.parentNode, 'Email harus diisi');
    } else if (!password) {
      const passwordInput = inputByName('password');
      passwordInput.focus();

      return putTooltip(passwordInput.parentNode, 'Kata sandi harus diisi');
    }

    this.setState({
      requestBtn: 'loading',
      requestError: false,
      errorMessage: '',
    });

    const { response, result: res } = await fetcher('/api/login', {
      body,
      csrfToken,
      baseurl: '/',
    }).post();

    this.setState({ requestBtn: 'normal' });

    if (!response.ok) {
      if (response.status === 422) {
        const firstErrorKey = Object.keys(res.data)[0];
        const firstErrorMsg = res.data[firstErrorKey][0];

        const inputError = inputByName(firstErrorKey);

        inputError.focus();
        putTooltip(inputError.parentNode, firstErrorMsg);

        return this.setState({
          requestError: true,
          errorMessage: firstErrorMsg,
        });
      } else if (response.status === 401) {
        return this.setState({
          requestError: true,
          errorMessage:
            'Login failed, please check your email or password and try again.',
        });
      }

      return this.setState({
        requestError: true,
        errorMessage: res.message,
      });
    }

    const {
      data: { token },
    } = res;

    if (!token) {
      this.setState({
        errorMessage:
          'Email atau password Anda salah, silahkan periksa kembali.',
      });

      return setTimeout(() => {
        this.setState({
          requestError: false,
        });
      }, 2000);
    }

    return this.setState({
      token,
    });
  }

  async doOTP(e) {
    e.preventDefault();
    const { setUser } = this.context;
    const { token } = this.state;
    const { csrfToken } = this.props;

    const otp = fieldValue('otp')?.trim();

    const body = {
      otp,
      token,
    };

    this.setState({
      requestBtn: 'loading',
    });

    /**
     * Request new token
     */
    const { response, result } = await fetcher('/api/otp', {
      body,
      csrfToken,
      baseurl: '/',
    }).post();

    this.setState({
      requestBtn: 'normal',
    });

    if (!response.ok) {
      let message = result.message;
      if (Object.keys(result.data).length > 0) {
        const firstErrorKey = Object.keys(result.data)[0];

        message = result.data[firstErrorKey][0];
      }

      return this.setState({
        requestError: true,
        errorMessage: message,
      });
    }

    setUser({
      profile: result,
    });

    Router.replace('/dashboard');
  }

  render() {
    const state = this.state;
    const { token, requestError, errorMessage, requestBtn } = state;
    const { recaptchaSiteKey } = this.props;

    return (
      <UnauthLayout
        action={token ? this.doOTP.bind(this) : this.doLogin.bind(this)}
        title="Sign In"
        description={
          token
            ? 'We sent a verification code to your email. Enter the code from the email in the field below.'
            : 'You need to sign in before continuing.'
        }>
        <div className="mt-6">
          {requestError && (
            <div className="px-4 py-3 mt-8 mb-6 text-sm text-red-600 bg-red-100 rounded">
              {errorMessage}
              {errorMessage.includes('8') && (
                <>
                  {` `}
                  If your current password is less than 8 characters, please
                  reset your password.{` `}
                  <ALink href="/forgot-password" className="underline">
                    Click here
                  </ALink>
                </>
              )}
            </div>
          )}

          {token ? (
            <FieldGroup>
              <Field
                autoFocus
                type="text"
                name="otp"
                icon={<Icon name="hash" width="18px" />}
                label="OTP Code"
                placeholder="6 digit code"
              />
            </FieldGroup>
          ) : (
            <>
              <FieldGroup>
                <Field
                  autoFocus
                  type="text"
                  name="email"
                  icon={<Icon name="user" width="18px" />}
                  label="Email"
                  placeholder="e.g. john@mail.com"
                />
              </FieldGroup>

              <FieldGroup>
                <Field
                  eye={true}
                  type="password"
                  name="password"
                  icon={<Icon name="lock" width="18px" />}
                  label="Password"
                  placeholder="Your registered password"
                />
              </FieldGroup>
            </>
          )}

          <ReCAPTCHA
            tabIndex={-1}
            sitekey={recaptchaSiteKey}
            size="invisible"
          />

          <div className="flex items-center">
            <ALink href="/forgot-password">Forgot Password</ALink>

            <Button type="submit" className="ml-auto" state={requestBtn}>
              Sign In
            </Button>
          </div>
        </div>

        {/*<div className="mt-12 text-center text-gray-600">
					<span className="font-light">Belum punya akun?</span> <ALink href="/register">Daftar sekarang</ALink>
				</div>*/}
      </UnauthLayout>
    );
  }
}

Index.contextType = UserContext;

export default Index;
